import React, { memo, useCallback, useEffect } from 'react';
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import { PusherContext } from 'src/features/pusher/PusherContext';
import { useAuthContext } from 'src/context/authContext';
import { useTeamsQuery } from 'src/entities/teams/queries/teamsQuery';
import { useQueryClient } from 'react-query';
import { NOTIFICATIONS_QUERY_KEYS } from 'src/entities/notifications/query/queryKeys';
import { browserIsSafari } from 'src/shared/utils/common';

export interface PusherNotification {
  body: string;
  title: string;
}

const beamInstanceId = 'c33f79a5-d364-43d6-871b-c33143cfa7d2';

const defaultBeams = ['coach-features', 'coach-updates', 'coach-news', 'coach-universe'];

export const beamsClient = new PusherPushNotifications.Client({
  instanceId: beamInstanceId,
});

export const PusherProvider: React.FC = memo(({ children }) => {
  const { currentUser } = useAuthContext();
  const queryClient = useQueryClient();

  const { id, clubId } = currentUser;

  const { data: teamsData, isLoading: isLoadingTeams } = useTeamsQuery();

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onMessage = (evt: MessageEvent): void => {
      queryClient.invalidateQueries(NOTIFICATIONS_QUERY_KEYS.base);
    };

    navigator.serviceWorker?.addEventListener('message', onMessage);
    return () => navigator.serviceWorker?.removeEventListener('message', onMessage);
  }, [queryClient]);

  const getDeviceInterests = useCallback(
    (teamsIds: number[]) =>
      import.meta.env.MODE === 'production'
        ? [...defaultBeams, `coach-org-${clubId}`, `coach-${id}`, ...teamsIds.map(teamId => `coach-team-${teamId}`)]
        : [
            ...defaultBeams.map(beam => `debug-${beam}`),
            `debug-coach-org-${clubId}`,
            `debug-coach-${id}`,
            ...teamsIds.map(teamId => `debug-coach-team-${teamId}`),
          ],
    [clubId],
  );

  const onInit = useCallback(async () => {
    const notificationsDisabled =
      (await beamsClient.getRegistrationState()) === PusherPushNotifications.RegistrationState.PERMISSION_DENIED;
    if (!teamsData || isLoadingTeams || notificationsDisabled) return;
    await beamsClient.start();

    const deviceId = await beamsClient.getDeviceId();
    if (!deviceId) return;

    // console.log(`pusher device id ${deviceId}`);

    const teamsIds = teamsData.teams.map(team => team.id);
    await beamsClient.setDeviceInterests(getDeviceInterests(teamsIds));
    // console.log(`pusher device interests ${getDeviceInterests(teamsIds)}`);
  }, [getDeviceInterests, isLoadingTeams, teamsData]);

  useEffect(() => {
    !browserIsSafari() && onInit();
  }, [onInit]);

  return (
    <PusherContext.Provider value={{ onClear: async () => await beamsClient.clearAllState(), onInit }}>
      {children}
    </PusherContext.Provider>
  );
});
